<template>
	<el-form ref="form" :model="form" label-position="left" label-width="100px" :rules="rules" class="cg-form">
		<el-form-item label="采购类型">
			<el-radio-group v-model="form.type">
				<el-radio :label="0" border>工程项目</el-radio>
				<el-radio :label="1" border>设备</el-radio>
				<el-radio :label="2" border>配件</el-radio>
				<el-radio :label="3" border>耗材</el-radio>
				<el-radio :label="4" border>原材料</el-radio>
			</el-radio-group>
		</el-form-item>
		<el-form-item label="采购商属性">
			<el-radio-group v-model="form.buyer_attr">
				<el-radio :label="1" border>生产商</el-radio>
				<el-radio :label="2" border>工程商</el-radio>
				<el-radio :label="3" border>贸易商</el-radio>
				<el-radio :label="4" border>终端用户</el-radio>
			</el-radio-group>
		</el-form-item>
		<el-form-item label="采购标题" prop="title">
			<el-input v-model="form.title"></el-input>
		</el-form-item>
		<el-form-item label="采购型号" prop="model">
			<el-input v-model="form.model"></el-input>
		</el-form-item>
		<el-form-item label="材质要求" prop="material">
			<el-input v-model="form.material"></el-input>
		</el-form-item>
		<el-form-item label="外形尺寸" prop="margin_size">
			<el-input v-model="form.margin_size"></el-input>
		</el-form-item>
		<el-form-item label="产品品牌" prop="brand">
			<el-input v-model="form.brand"></el-input>
		</el-form-item>
		<el-form-item label="采购数量" prop="num">
			<el-input v-model.number="form.num"></el-input>
		</el-form-item>
		<el-form-item label="联系人" prop="contacts">
			<el-input v-model="form.contacts"></el-input>
		</el-form-item>
		<el-form-item label="联系电话" prop="mobile">
			<el-input v-model="form.mobile"></el-input>
		</el-form-item>
		<el-form-item label="应用行业" prop="yyhy">
			<el-input v-model="form.yyhy"></el-input>
		</el-form-item>
		<el-form-item label="项目地" prop="project_site">
			<area-select v-model="selected1" :data="pca" type="text" :level="0" @change="getSite1" />
		</el-form-item>
		<el-form-item label="截止日期" prop="endtime">
			<el-date-picker v-model="form.endtime" type="date" placeholder="选择日期" format="yyyy-MM-dd"
				value-format="yyyy-MM-dd" :picker-options="pickerOptions" />
		</el-form-item>
		<el-form-item label="报价截止后">
			<el-radio-group v-model="form.bjjz_day">
				<el-radio label="0" border>到期关闭</el-radio>
				<el-radio label="7" border>续7天</el-radio>
				<el-radio label="15" border>续15天</el-radio>
				<el-radio label="30" border>续30天</el-radio>
				<el-radio label="3400" border>长期采购</el-radio>
			</el-radio-group>
		</el-form-item>
		<el-form-item label="分类" prop="cat_id">
			<el-cascader :options="options" :props="props" v-model="form.cat_id" @change="catidChange" />
		</el-form-item>
		<el-form-item label="供应商地域">
			<area-select :data="pca" type="text" v-model="selected2" @change="getSite2">
			</area-select>
		</el-form-item>
		<el-form-item label="产品描述" prop="content">
			<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.content">
			</el-input>
		</el-form-item>
		<el-form-item label="供应商要求" prop="supplier_require">
			<el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="form.supplier_require">
			</el-input>
		</el-form-item>
		<el-form-item>
			<el-button type="primary" @click="onSubmit">提交</el-button>
		</el-form-item>
	</el-form>
</template>

<script>
	import {
		pca,
		pcaa
	} from 'area-data'
	import 'vue-area-linkage/dist/index.css'
	export default {
		data() {
			let checkPhone = (res, val, callback) => {
				let reg1 = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/;
				let reg2 = /0\d{2,3}-\d{7,8}/;
				if (reg1.test(val) || reg2.test(val)) {
					callback();
				} else {
					return callback(new Error('请输入正确的手机号或座机号'));
				}
			}
			return {
				form: {
					id: '',
					type: 0,
					buyer_attr: 1,
					title: '',
					model: '',
					material: '',
					margin_size: '',
					brand: '',
					num: '',
					contacts: '',
					mobile: '',
					yyhy: '',
					project_site: '',
					endtime: '',
					bjjz_day: '0',
					cat_id: '',
					province: '',
					city: '',
					content: '',
					supplier_require: '',
					user_id:this.$store.state.SELLER_ID,
				},
				pca,
				pcaa,
				selected1: [],
				selected2: [],
				props: {
					label: 'catname',
					value: 'id'
				},
				options: [],
				rules: {
					title: [{
						required: true,
						message: '请填写采购标题',
						trigger: 'blur'
					}],
					num: [{
							required: true,
							message: '请填写数量',
							trigger: 'blur'
						},
						{
							type: 'number',
							message: '数量必须是数字值',
							trigger: ['blur', 'change']
						}
					],
					contacts: [{
						required: true,
						message: '请填写联系人',
						trigger: 'blur'
					}],
					mobile: [{
							required: true,
							message: '请填写联系电话',
							trigger: 'blur'
						},
						{
							validator: checkPhone,
							trigger: ['blur', 'change']
						}
					],
					cat_id: [{
						required: true,
						message: '请选择分类',
						trigger: ['blur', 'change']
					}]
				},
				pickerOptions: {
					disabledDate(time) {
						return time.getTime() < Date.now()
					}
				}
			}
		},
		created() {
			this.$axios.get('/getCategory').then(data => {
				this.options = data
			})
			let date1 = new Date(),
				time1 = date1.getFullYear() + "-" + (date1.getMonth() + 1) + "-" + date1.getDate(),
				date2 = new Date(date1);
			date2.setDate(date1.getDate() + 7)
			this.form.endtime = date2.getFullYear() + "-" + (date2.getMonth() + 1) + "-" + date2.getDate();
		},
		methods: {
			catidChange(data) {
				this.form.cat_id = data[data.length - 1]
			},
			getSite1(data) {
				this.form.project_site = data[0];
			},
			getSite2(data) {
				this.form.province = data[0]
				this.form.city = data[1]
			},
			onSubmit() {
				this.$refs.form.validate(valid => {
					if (valid) {
						// this.$axios.post('/saveCinfo', this.form).then(res => {
						// 	if (res.code == 0) {
						// 		this.$message({
						// 			message: '更新完成',
						// 			type: 'success'
						// 		})
						// 		this.$router.push('/purchase');
						// 	}else{
						// 		this.$message({
						// 			message: data.msg,
						// 			type: 'error'
						// 		})
						// 	}
						// })
						this.$emit('eventForm', this.form)
					} else {
						this.$message.error('请补全必填项')
						return false
					}
				})
			}
		},
		watch: {
			purForm(val) {
				this.form = val
				this.selected1.push(this.form.project_site)
				this.selected2.push(this.form.province, this.form.city)
			}
		},
		props: ['purForm']
	}
</script>

<style scoped>
	.cg-form {
		width: 900px;
	}

	.area-select-wrap>>>.area-select {
		height: 40px;
	}

	.area-select-wrap>>>.area-selected-trigger {
		padding: 0 20px 0 12px;
	}
</style>
