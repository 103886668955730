<template>
	<div class="o-wrap">
		<el-page-header @back="$router.go(-1)" content="修改采购" style="padding: 20px 10px;"/>
		<PurForm :purForm="form" class="purForm" @eventForm="handleGetForm" />
	</div>
</template>

<script>
	import PurForm from '@/components/Purchase/Form'
	import Axios from "axios"
	export default {
		data() {
			return {
				form: {}
			}
		},
		created() {
			Axios.post('/getCinfo', {
				id: this.$route.query.id,
			}).then(data => {
				let datas = data.data
				if(datas.code == 200){
					datas.data.num *= 1;
					this.form = datas.data;
				}else{
					 this.$message(datas.msg);
					 
				}
			})
		},
		methods:{
			handleGetForm(form) {
				
				Axios.post('/saveCinfo', form).then(data => {
					let res = data.data
					if (res.code == 200) {
						this.$message({
							message: '更新完成',
							type: 'success'
						})
						this.$router.push('/purchase');
					}else{
						this.$message({
							message: res.msg,
							type: 'error'
						})
					}
				})
			}
		},
		components: {
			PurForm
		}
	}
</script>
